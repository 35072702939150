@use "./node_modules/include-media/dist/_include-media.scss" as *;
.backdrop {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 2px;
    left: 0;
    background: rgb(0 0 0 / 75%);
    z-index: 10;
}

.outer {
    position: fixed;
    width: 600px;
    height: 600px;
    max-width: 100%;
    max-height: 100%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 11;
    border: 2px solid rgba(255 255 255 / 10%);
    padding: 0 1.75rem 1.5rem;
    overflow: hidden;
    display: grid;
    grid-template-rows: auto 1fr;

    /* 2 rows */

    // black on white
    --textcolor-rgb: var(--ink-rgb);
    --linkcolor-rgb: var(--link-rgb);

    color: rgb(var(--textcolor-rgb));
    background: rgb(var(--paper-rgb));
}

.header {
    position: relative;
    display: flex;
    align-items: center;
    padding: 1rem 0;
    border-bottom: 1px solid rgba(0 0 0 / 25%);
}

.title {
    flex: 1;
}

.content {
    padding-top: 1rem;
    overflow: auto;
    -ms-overflow-style: none;

    /* IE and Edge */
    scrollbar-width: none;

    /* Firefox */
    vertical-align: top;

    h2,
    h3,
    h4,
    h5 {
        margin-bottom: 0.25rem;
    }

    p {
        margin-bottom: 0.5rem;
    }
}

/* Chrome, Safari and Opera */
.content::-webkit-scrollbar {
    display: none;
}
