@use "./node_modules/include-media/dist/_include-media.scss" as *;
%h1,
%h2,
%h3,
%h4,
%h5,
%h6 {
    font-style: normal;
    line-height: 1.2;
}

%h1 {
    font-size: 2rem;

    @include media('<tablet') {
        font-size: 1.75rem;
    }
}

%h2 {
    font-size: 1.5rem;

    @include media('<tablet') {
        font-size: 1.375rem;
    }
}

%h3 {
    font-size: 1.25rem;
    line-height: 1.4;
}

%h4 {
    font-size: 1.125rem;
    line-height: 1.4;
}

%h5 {
    font-size: 1rem;
    line-height: 1.4;
}

%h6 {
    font-size: 0.875rem;
    line-height: 1.4;
}

%p,
%li {
    font-size: 1rem;
    line-height: 1.6;

    a {
        color: rgb(var(--linkcolor-rgb));
        text-decoration: underline;
        text-decoration-color: rgba(var(--linkcolor-rgb) / 33%);
        transition: text-decoration-color var(--transition-duration);

        &:hover {
            text-decoration-color: rgba(var(--linkcolor-rgb) / 100%);
        }
    }
}

%ul,
%ol {
    padding-left: 1.25rem;
}

%ul {
    list-style: disc outside;
}

%ol {
    list-style: decimal outside;
}

%li {
    list-style: inherit;
    margin-bottom: 0.25rem;
}

%caps-small {
    font-family: var(--font-sans);
    font-size: 0.875rem;
    line-height: 1;
    letter-spacing: 0.025em;
    text-transform: uppercase;
}

.h1,
.typo :global(h1) {
    @extend %h1;
}

.h2,
.typo :global(h2) {
    @extend %h2;
}

.h3,
.typo :global(h3) {
    @extend %h3;
}

.h4,
.typo :global(h4) {
    @extend %h4;
}

.h5,
.typo :global(h5) {
    @extend %h5;
}

.h6,
.typo :global(h6) {
    @extend %h6;
}

.p,
.typo :global(p) {
    @extend %p;
}

.ul,
.typo :global(ul) {
    @extend %ul;
}

.ol,
.typo :global(ol) {
    @extend %ol;
}

.li,
.typo :global(li) {
    @extend %li;
}

.capsSmall,
.typo :global(.capsSmall) {
    @extend %caps-small;
}

.small,
.typo :global(small) {
    @extend %p;

    font-family: var(--font-sans);
    font-size: 0.875rem;
    line-height: 1.4;
}

.typo {
    overflow-wrap: break-word;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    ul,
    ol {
        margin-bottom: 1rem;

        &:last-child {
            margin-bottom: 0;
        }
    }

    /* Strapi Weirdness */
    /* stylelint-disable-next-line no-descending-specificity */
    ul > ul {
        margin-bottom: 0;
    }

    img {
        max-width: 100%;
        height: auto;
    }

    dt {
        @extend %caps-small;

        margin-bottom: 0.25rem;
    }

    dd {
        @extend %p;

        margin-bottom: 1.5rem;
    }

    > .typo {
        margin-bottom: 1rem;

        &:last-child {
            margin-bottom: 0;
        }
    }

    ul + *:not(p, ul, li),
    p + *:not(p, ul, li) {
        margin-top: 2rem;
    }
}

.center {
    text-align: center;
}
